<template>
  <div>
    <div class="text-end" v-if="hasCodeFederal">
      <v-btn class="ma-2 btn btn-primary" @click="modalNewsIsOpen = true">
        Ajouter une actualité
      </v-btn>
    </div>
    <v-data-table
      :key="componentKey"
      :headers="headers"
      :items="newsList"
      :hide-default-footer="showpagination"
      class="elevation-1 newslist"
      item-key="key"
      :loading="loading"
      :custom-sort="customSort"
    >
      <template v-slot:item.image="{ item }">
        <font-awesome-icon icon="image" v-if="item.DOC_Document" />
        <span v-else>-</span>
      </template>

      <template v-slot:item.date="{ item }">
        {{ $moment(item.DateParution).format("DD/MM/YYYY") }}
      </template>

      <template v-slot:item.title="{ item }">{{ item.NewsTitre }}</template>
      
      <template v-slot:item.type="{ item }">{{ item.LIST_NewsType ? item.LIST_NewsType.NomTypeNews : 'Actualité' }}</template>

      <template v-slot:item.actions="{ item }">
        <NewsListTableActions
          v-if="hasCodeFederal"
          :news="item"
          :ID_Structure="ID_Structure"
          @onEdited="newsEdited"
          @onDeleted="newsDeleted"
        ></NewsListTableActions>
      </template>
    </v-data-table>

    <NewsModal
      :isOpen="modalNewsIsOpen"
      v-if="modalNewsIsOpen"
      :ID_Structure="ID_Structure"
      @onAdded="newsAdded"
      @onEdited="newsEdited"
      @onClose="closeModal"
    ></NewsModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NewsModal from "@/components/utils/modals/news/news.vue";
import NewsListTableActions from "@/components/News/NewsListTableActions.vue";

export default {
  name: "component__News",

  components: { NewsModal, NewsListTableActions },

  data: () => ({
    loading: true,
    mounted: false,
    modalNewsIsOpen: false,
    componentKey: 0,
    headers: [],

    newsList: [],
    currentNews: {
      NewsTitre: "",
      NewsTexte: "",
      DateParution: "",
    },
  }),

  async created() {
    // this.newsList = await this.getNewsByStructureID({
    //   ID_Structure: this.ID_Structure
    // });
    this.setData();

    this.headers = this.getHeaders;
    this.loading = false;
    this.mounted = true;
  },

  computed: {
    ...mapGetters("structure", [
      "currentStructure",
      "getStructure",
      "getFFME",
      "hasCodeFederal",
    ]),

    getHeaders() {
      let headers = [
        { text: "Date de parution", value: "date" },
        { text: "Titre de l'actualité", value: "title" },
        { text: "Image", value: "image" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ];

      if (this.currentStructureId === 1318) {
        headers.splice(headers.length - 1, 0, { text: "Type d'actualité", value: "type" });
      }

      return headers;
    },

    currentStructureId() {
      return this.currentStructure.STR_Structure.id;
    },

    showpagination() {
      if (!this.loading) return this.newsList.length <= 10;
      return false;
    },

    modalTitle() {
      return this.modal.add ? this.modal.title.add : this.modal.title.edit;
    },

    modalSubmitText() {
      return this.modal.add ? this.modal.submit.add : this.modal.submit.edit;
    },
  },

  methods: {
    ...mapActions("structure", ["getNewsByStructureID"]),

    customSort(items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "date") {
          if (!isDesc[0]) {
            return new Date(b.DateParution) - new Date(a.DateParution);
          } else {
            return new Date(a.DateParution) - new Date(b.DateParution);
          }
        } else if (index[0] == "image") {
          if (!isDesc[0]) {
            return new Date(b.ID_DocumentImage) - new Date(a.ID_DocumentImage);
          } else {
            return new Date(a.ID_DocumentImage) - new Date(b.ID_DocumentImage);
          }
        }
      });
      return items;
    },

    closeModal() {
      this.modalNewsIsOpen = false;
    },

    async setData() {
      let structureNews = await this.getNewsByStructureID({
        ID_Structure: this.ID_Structure,
      });

      if (structureNews.length) {
        return (this.newsList = [
          ...structureNews.sort(
            (a, b) => new Date(b.DateParution) - new Date(a.DateParution)
          ),
        ]);
      }
      this.newsList = [];
    },

    newsAdded(item) {
      this.setData();
      this.closeModal();
    },

    newsEdited(item) {
      this.setData();
      this.componentKey += 1;
      this.closeModal();
    },

    newsDeleted() {
      this.setData();
      this.componentKey += 1;
    },

    onClosingModal() {
      this.currentNews = {};
    },

    editNews(id) {
      this.currentNews = this.news.filter((ele) => ele.key === id)[0];
      if (this.currentNews.links) {
        this.currentNews.links.forEach((ele, index) => {
          this.currentNews["link" + (index + 1)] = ele.Url;
        });
      }
    },

    rowClicked() {},

    onNewsSaved() {},
  },

  props: {
    ID_Structure: {
      type: [Number],
      default: null,
    },

    NewsType: {
      type: [String],
      default: null,
    },
  },
};
</script>

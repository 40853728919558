<template>
  <div class="text-right">
    <v-btn icon color="primary" @click.prevent="modalNewsIsOpen = true">
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn icon color="red" class="ml-5" @click.prevent="deleteNews">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <NewsModal
      :item="news"
      :isOpen="modalNewsIsOpen"
      :ID_Structure="ID_Structure"
      v-if="modalNewsIsOpen"
      @onEdited="onEdited"
      @onClose="closeModal"
    ></NewsModal>
  </div>
</template>

<script>
import NewsModal from "@/components/utils/modals/news/news.vue";
import {
  success_notification,
  error_notification
} from "@/plugins/notifications";
import { mapActions } from "vuex";
export default {
  components: { NewsModal },

  data: () => ({
    modalNewsIsOpen: false
  }),

  created() {},

  methods: {
    ...mapActions("structure", ["deleteStructureNews"]),

    closeModal() {
      this.modalNewsIsOpen = false;
    },

    onEdited(item) {
      this.$emit("onEdited", item);
      this.closeModal();
    },

    async deleteNews() {
      try {
        await this.deleteStructureNews({
          news: this.news,
          ID_Structure: this.ID_Structure
        });
        success_notification("Actualité supprimée");
        this.$emit("onDeleted", this.news);
      } catch (e) {
        error_notification("Oups, une erreur est survenue");
      }
    }
  },

  props: {
    news: {
      type: Object
    },

    ID_Structure: {
      type: [Number],
      default: null
    },

    NewsType: {
      type: [String],
      default: null
    }
  }
};
</script>

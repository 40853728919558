<template>
  <v-dialog
    v-if="isOpen"
    v-model="isOpen"
    @click:outside="onClose"
    persistent
    max-width="1200px"
    class="ffme_dialog"
  >
    <div v-if="!loading">
      <v-tabs v-model="tab" value="slug" background-color="blue-dark" dark grow>
        <v-tabs-slider></v-tabs-slider>

        <v-tab style="display:flex;flex-direction:column;">
          <span style="font-size:1.2rem;">
            {{
              selectedNews ? "Editer une actualité" : "Ajouter une actualité"
            }}
          </span>

          <v-icon class="close-icon" @click="onClose">mdi-close</v-icon>
        </v-tab>
      </v-tabs>

      <div class="modal__content">
        <v-tabs-items>
          <v-card flat>
            <addEditNews
              :selectedNews="selectedNews"
              :ID_Structure="structure_id"
              :NewsType="NewsType"
              @onSelected="onItemSelected"
              @onEdited="onItemEdited"
            ></addEditNews>
          </v-card>
        </v-tabs-items>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import addEditNews from "@/components/utils/modals/news/addEditNews.vue";
import { mapGetters } from "vuex";

export default {
  components: { addEditNews },
  data() {
    return {
      tab: null,
      loading: true,
      structure_id: null,
      selectedNews: null,
      // items: [
      //   { tab: "Ajouter une news", componentName: "addNews" },
      //   { tab: "Editer une news", componentName: "editNews" }
      // ]
    };
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId"]),
  },

  created() {
    this.tab = 0;
    if (this.item) {
      this.tab = 1;
      this.selectedNews = this.item;
    }
    if (this.selection) {
      this.tab = 1;
    }
    this.structure_id = this.ID_Structure
      ? this.ID_Structure
      : this.currentStructureId;

    this.loading = false;
  },

  methods: {
    onClose() {
      this.tab = 0;
      this.$emit("onClose");
    },

    onItemSelected(item) {
      this.$emit("onSelected", item);
    },

    onItemEdited(item) {
      this.$emit("onEdited", item);
    },
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: null,
    },

    selection: {
      type: Boolean,
      default: false,
    },

    ID_Structure: {
      type: [Number],
      default: null,
    },

    NewsType: {
      type: [String],
      default: null,
    },
  },
};
</script>

<style lang="scss">
.v-dialog {
  background: #fff !important;
}
</style>

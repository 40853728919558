<template>
  <div>
    <Loading :loading="isSaving" fixed />

    <div style="padding: 20px;">
      <ValidateForm
        v-if="mounted"
        @validated="submit"
        :loading="loading"
        :showFieldObl="mandatory"
      >
        <v-row>
          <v-col :cols="news.isDirectInfo ? 12 : 6">
            <v-row>
              <v-col cols="12">
                <InputText
                  v-model="currentNews.NewsTitre"
                  label="Titre de l'actualité"
                  rules="required"
                ></InputText>
              </v-col>
              <v-col cols="12">
                <div class="datePickerFullWidth">
                  <datePicker
                    rules="date|required"
                    v-model="currentNews.DateParution"
                    label="Date de la parution"
                    withIcon="false"
                  />
                </div>
              </v-col>
            </v-row>
            <v-row v-if="currentStructureId === 1318">
                <v-col
                  cols="12"
                  md="3"
                  class="field__label-column"
                >
                  <label for=""
                    >Type d'actu
                    </label
                  >
                </v-col>
                <v-col
                  cols="12"
                  md="9"
                >
                <v-select
                  :items="['Actualité', 'Direct\'Info']"
                  label="Choisir un type"
                  solo
                  class="input__select"
                  @change="val => news.isDirectInfo = (val === 'Direct\'Info')"
                  :value="news.isDirectInfo ? 'Direct\'Info' : 'Actualité'"
                ></v-select>
              </v-col>
              </v-row>

            <TextArea
              v-model="currentNews.NewsTexte"
              :label="news.isDirectInfo ? 'URL de l\'actualité' : 'Contenu'"
              twoLines
              rules="required"
            ></TextArea>
          </v-col>
          <v-col cols="6" v-if="!news.isDirectInfo">
            <ImageUpload
              :uploading="loading"
              :preview="false"
              @fileSelected="onFileSelected"
              :startAutoUpload="startAutoUpload"
              @actionUpload="onUploadDone"
              :currentImageUrl="
                currentNews.DOC_Document
                  ? currentNews.DOC_Document.CheminDocument
                  : ''
              "
              autoUpload
              @save-actu="submit"
              @remove-register="showRegister = false"
            >
              <v-btn
                v-if="!showRegister || !selectedNews"
                @click.prevent="submit"
                class="btn btn-primary"
                >Enregistrer</v-btn
              >
            </ImageUpload>
          </v-col>
        </v-row>

        <v-card-actions class="px-0">
          <v-spacer></v-spacer>
          <v-btn
            v-if="(showRegister && selectedNews) || (!selectedNews && news.isDirectInfo)"
            type="submit"
            :loading="loading"
            class="btn btn-primary"
            style="margin-top:4px"
            >Enregistrer</v-btn
          >
        </v-card-actions>
      </ValidateForm>

      <div v-else>Chargement ...</div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ValidateForm from "@/components/utils/form.vue";
import TextArea from "@/components/utils/textarea.vue";
import InputText from "@/components/utils/input.vue";
import ImageUpload from "@/components/utils/image_upload.vue";
import Loading from "@/components/LoadingIndicator.vue";
import { success_notification } from "@/plugins/notifications";

export default {
  components: {
    ValidateForm,
    TextArea,
    InputText,
    ImageUpload,
    datePicker: () =>
      import(
        /* webpackChunkName: "input__datepicker" */ "@/components/utils/datePicker.vue"
      ),
    Loading,
  },

  data: () => ({
    news: {
      NewsTitre: "",
      NewsTexte: "",
      DateParution: "",
      ID_DocumentImage: "",
      isDirectInfo: false,
    },
    showRegister: true,
    currentNews: null,
    loading: true,
    mounted: false,
    isSaving: false,
    selected_filename: null,
    image_doc: "",
    startAutoUpload: false,
    mandatory: false,
  }),

  async created() {
    await this.getListTypeNews();

    if (this.selectedNews) {
      if (this.selectedNews.LIST_NewsType !== null) {
        this.news.isDirectInfo = true;
      }

      this.setData(this.selectedNews)
    } else {
      this.setData();
    }

    this.mounted = true;
    this.loading = false;
  },

  computed: {
    ...mapGetters("basics", ["getDocumentTypeImageNews", "getNewsTypeBySlug"]),

    currentStructure() {
      return this.getStructure(this.currentStructureId);
    },

    currentStructureId() {
      return this.ID_Structure;
    },
  },

  methods: {
    ...mapActions("basics", ["insertDocument", "getListTypeNews"]),
    ...mapActions("structure", ["updateStructureNews", "updateNewsImg"]),

    async setData(data) {
      this.currentNews = { ...data };
    },

    async getDirectInfoTypeId() {
      return await this.getNewsTypeBySlug("direct_info").id;
    },

    async submit() {
      this.isSaving = true;

      // if an image file is selected, first upload that one
      if (this.selected_filename) {
        // starting image upload
        this.startAutoUpload = true;
        // console.log("uploading");
        // no image selected, so save news as is
      } else this.updateNews();
    },

    async updateNews() {
      this.currentNews.ID_Structure = this.currentStructureId;
      if (!this.currentNews.ID_DocumentImage)
        this.currentNews.ID_DocumentImage = null;
      try {
        if (this.currentNews.id) {
          delete this.currentNews.__typename;
          delete this.currentNews.DOC_Document;
          delete this.currentNews.STR_Structure;
        }

        if (this.selectedNews) {
          delete this.currentNews.LIST_NewsType;
        }

        if (this.news.isDirectInfo) {
          this.currentNews.ID_TypeNews = await this.getDirectInfoTypeId();
        }

        let response = await this.updateStructureNews({
          news: this.currentNews,
        });
        this.$emit("onEdited", response);
        if (this.currentNews.id) {
          success_notification("Actualité mise à jour");
        } else {
          success_notification("Actualité enregistrée");
        }
      } catch (e) {
        console.log(e);
        // console.log("ERRORRRRRRR");
      }
    },

    async onFileSelected(filename) {
      this.selected_filename = filename;
    },

    async onUploadDone(data) {
      // console.log("data:", data);
      this.startAutoUpload = false;

      // save the image
      // let response = await this.insertDocument({
      //   ...data,
      //   ID_Utilisateur: null,
      //   ID_Structure: this.currentStructureId,
      //   ID_Type_Document: this.getDocumentTypeImageNews.id
      // });
      let response = await this.updateNewsImg({
        ...data,
        ID_Utilisateur: null,
        ID_Structure: this.currentStructureId,
        ID_Type_Document: this.getDocumentTypeImageNews.id,
      });

      this.image_doc = response.data.insert_DOC_Document.returning[0];
      this.currentNews.ID_DocumentImage = this.image_doc.id;
      // if (!isEmpty(this.image_doc)) {
      //    this.image_doc = this.image_doc[0]
      // }

      // reset image uploader

      // now save the news
      this.updateNews();
    },
  },

  props: {
    ID_Structure: {
      type: [Number],
      default: null,
    },

    NewsType: {
      type: [String],
      default: null,
    },

    selectedNews: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
.datePickerFullWidth * {
  max-width: none !important;
}
</style>

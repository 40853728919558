<template>
  <div class="w-100">
    <v-row justify="center">
      <v-col cols="12" md="9" class="text-center __font-600 pt-0">
        Vous pouvez transmettre des informations à vos licenciés.
        <br />
        Elles apparaîtront dans les nouveautés de votre
        {{ TypeStructureName }}, sur l’espace personnel des licenciés.
      </v-col>
    </v-row>

    <v-row v-if="mounted" justify="center">
      <v-col cols="12" xl="10" class>
        <NewsListComponent
          :ID_Structure="structureID"
        ></NewsListComponent>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import NewsListComponent from "@/components/News/NewsListTable.vue";

export default {
  name: "InformationDuClub__News",

  components: { NewsListComponent },

  data: () => ({
    mounted: false,
    structureID: null,
  }),

  async created() {
    this.loading = false;
    this.mounted = true;
    this.setData();
  },

  computed: {
    ...mapGetters("structure", ["currentStructureId", "currentStructureType"]),

    TypeStructureName() {
      if (this.currentStructureType.EST_Club === true) return "structure";

      let str = this.currentStructureType.NomTypeStructure.toLowerCase();
      return str;
    },
  },

  methods: {
    setData() {
      this.structureID = parseInt(this.$route.params.id);
      if(!this.structureID) this.structureID = this.currentStructureId;
      console.log("this.structureID *********", this.structureID);
    },
  },
};
</script>

<style lang="scss"></style>

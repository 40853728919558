var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasCodeFederal)?_c('div',{staticClass:"text-end"},[_c('v-btn',{staticClass:"ma-2 btn btn-primary",on:{"click":function($event){_vm.modalNewsIsOpen = true}}},[_vm._v(" Ajouter une actualité ")])],1):_vm._e(),_c('v-data-table',{key:_vm.componentKey,staticClass:"elevation-1 newslist",attrs:{"headers":_vm.headers,"items":_vm.newsList,"hide-default-footer":_vm.showpagination,"item-key":"key","loading":_vm.loading,"custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [(item.DOC_Document)?_c('font-awesome-icon',{attrs:{"icon":"image"}}):_c('span',[_vm._v("-")])]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.DateParution).format("DD/MM/YYYY"))+" ")]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.NewsTitre))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.LIST_NewsType ? item.LIST_NewsType.NomTypeNews : 'Actualité'))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasCodeFederal)?_c('NewsListTableActions',{attrs:{"news":item,"ID_Structure":_vm.ID_Structure},on:{"onEdited":_vm.newsEdited,"onDeleted":_vm.newsDeleted}}):_vm._e()]}}])}),(_vm.modalNewsIsOpen)?_c('NewsModal',{attrs:{"isOpen":_vm.modalNewsIsOpen,"ID_Structure":_vm.ID_Structure},on:{"onAdded":_vm.newsAdded,"onEdited":_vm.newsEdited,"onClose":_vm.closeModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }